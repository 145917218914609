<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add Schedule Ngo Other Course
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 60%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="12">

       

          
         
            <b-col md="12">
              <b-form-group
                label="Select Schedule Date"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="schedule"
                  rules="required"
                >
                  <v-select
                    id="blog-edit-category"
                    v-model="addCourseForm.schedule"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="date"
                    multiple
                    :options="scheduleList"
                    :reduce="(val) => val.id"
                  >
                    <template #no-options="{ search, searching, loading }">
                      Select City and Course ...
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default {
  setup(props, { emit }) {
    const { route } = useRouter()
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const coursesList = ref([])
    const categoriesList = ref([])
    const scheduleList = ref([])
    const citiesList = ref([])
    const addCourseForm = reactive({
      category_id: '',
      type: '',
      course: '',
      city: '',
      schedule: [],
      slider: '',
    })
    const dedicated = ref(false)
    store.dispatch('categories/CategoryList').then(response => {
      categoriesList.value = response.data
    })
    const getCourseSchedule = id => {
      if (id == 'dedicated') {
        dedicated.value = true
        store.dispatch('homepage/getScheduleDate').then(response => {
          scheduleList.value = response.data.data
        })
        store.dispatch('homepage/getOtherCourse', { id }).then(response => {
          coursesList.value = response.data.data
        })
      }
      if (id == 'online') {
        dedicated.value = false
        store
          .dispatch('courses/getAllCoursesOnline')
          .then(response => {
            coursesList.value = response.data.data
          })
      }
      if (id == 'classic') {
        dedicated.value = false
        store
          .dispatch('courses/getAllClassical')
          .then(response => {
            coursesList.value = response.data.data
          })
      }
    }
    const getscheduleByCourse = (cityid, courseid) => {
      store
        .dispatch('homepage/getscheduleByCourse', { cityid, courseid })
        .then(response => {
          scheduleList.value = response.data
        })
    }
    const getscheduleByCourseOnline = (cityid, courseid) => {
      if (addCourseForm.type == 'online') {
        store
          .dispatch('homepage/getscheduleByCourse', { cityid, courseid })
          .then(response => {
            scheduleList.value = response.data
          })
      }
    }
    getCourseSchedule('dedicated')
    const typeList = [
      {
        name: 'Classic',
        id: 'classic',
      },
      {
        name: 'Online',
        id: 'online',
      },

    ]
    const { id } = route.value.params

    const getCoursesByCategory = id => {
      let online = null
      if (addCourseForm.type == 'online') {
        online = 1
      } else {
        online = 0
      }
      store
        .dispatch('courses/getAllCoursesList', {
          'filter[category_id]': id,
          'filter[online]': online,
        })
        .then(response => {
          coursesList.value = response.data
        })
    }
    store.dispatch('cities/cititesList').then(response => {
      citiesList.value = response.data
    })
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          const data = {
            otherScheduleIds: addCourseForm.schedule,
          }
          store
            .dispatch('setting/addScheduleNgosOther', { id, data })
            .then(response => {
              addCourseForm.schedule = ''
              addCourseForm.type = ''
              addCourseForm.slider = ''
              addCourseForm.course = ''
              addCourseForm.category_id = ''
              addCourseForm.city = ''
              addCourseForm.slider = ''

              emit('add-course')
              Vue.swal({
                title: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    return {
      refInputEl,
      dedicated,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      typeList,
      categoriesList,
      getCoursesByCategory,
      citiesList,
      getCourseSchedule,
      scheduleList,
      getscheduleByCourseOnline,
      getscheduleByCourse,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,

    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
